<template>
    <div>
        <el-form ref="queryForm" class="query-box"  v-model="queryParams" label-width="68px">
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="公告类型" prop="type">
                        <el-select v-model="queryParams.noticeType"
                                   placeholder="公告类型" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option label="系统消息" value="MESSAGE"/>
                            <el-option label="活动通知" value="ACTIVITY"/>
                        </el-select>
                    </el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="生效日期" prop="name">
                        <el-date-picker
                        v-model="queryParams.effectiveDate"
                        type="date" style="width: 100%"
                        placeholder="请输入生效日期">
                        </el-date-picker>
                    </el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="通知日期" prop="name">
                        <el-date-picker
                        v-model="queryParams.noticeDate"
                        type="date" style="width: 100%"
                        placeholder="请输入通知日期">
                        </el-date-picker>
                    </el-form-item>
				</el-col>
				<el-col :span="6">
                    <el-form-item label="通知范围" prop="type">
                        <el-select v-model="queryParams.noticeRange" placeholder="通知范围" clearable size="small" style="width: 100%">
                            <el-option label="全部" value="ALL"/>
                            <el-option label="一级招商中心" value="ORG1"/>
                            <el-option label="二级招商中心" value="ORG2"/>
                            <el-option label="三级招商中心" value="ORG3"/>
                            <el-option label="一级代理商" value="AGENT1"/>
                            <el-option label="二级代理商" value="AGENT1"/>
                            <el-option label="三级代理商" value="AGENT1"/>
                            <el-option label="四级代理商" value="AGENT1"/>
                            <el-option label="五级代理商" value="AGENT1"/>
                            <el-option label="六级代理商" value="AGENT1"/>
                            <el-option label="指定范围" value="RANGE"/>
                        </el-select>
                    </el-form-item>
				</el-col>
			</el-row>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini" v-permission="'NOTICE_INFO_QUERY'"
                           @click="handleQuery">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini" v-permission="'NOTICE_INFO_QUERY'"
                           @click="handleResetQuery">
                    重置
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini"
                           @click="handleAdd"
                           v-permission="'NOTICE_INFO_INSERT'">
                    添加
                </el-button>
            </el-col>
        </el-row>

        <CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true"></CustomForm>
    </div>
</template>

<script>
    import CustomForm from "./CustomForm";
    import {UserApi} from '@/api';
    export default {
        name: "ToolBar",
        components: {CustomForm},
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
            }
        },
        mounted() {
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出公告",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await UserApi.noticeInfo.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                let [startCreateTime, endCreateTime] = this.createDateRange;
                this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
                this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
